<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          md="11"
        >
          Standard Cluster
        </b-col>
        <b-col
          md="1"
          class="pl-0"
        >
          <b-button
            variant="outline-success"
            :disabled="isProcessing"
            @click="saveClusterToFinal"
          >
            {{ $t('actions.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="isProcessing"
    >
      <b-row>
        <b-col
          md="6"
        >
          <b-card
            no-body
            class="mb-0 p-2"
          >
            <h5
              class="font-weight-bold mb-2 bg-light text-dark p-2"
            >
              Clusters
            </h5>
            <div
              style="height: 450px; overflow: scroll;"
            >
              <b-card
                v-for="cluster, index in clusters"
                :key="index"
                border-variant="secondary"
                no-body
                :class="{ selected: cluster.cluster === selectedCluster }"
                class="mb-0 p-2 mb-1 cursor-pointer"
                @click="getStandard(cluster.cluster)"
              >
                {{ cluster.cluster === -1 ? 'Noise' : `Cluster ${cluster.cluster}` }}
                <b-badge
                  pill
                  style="position: absolute;right: 0;margin-right: 10px;z-index: 1000;"
                >
                  {{ cluster.standards_count }}
                </b-badge>
              </b-card>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="paginationData.totalItems"
                :per-page="paginationData.perPage"
                :disabled="isProcessing"
                first-number
                last-number
                align="center"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="(value) => getClusters(value)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card>

        </b-col>
        <b-col
          md="6"
        >
          <b-card>
            <h5
              class="font-weight-bold mb-2 bg-light text-dark p-2"
            >
              Standards for Cluster {{ selectedCluster }}
            </h5>
            <b-overlay
              :show="isFetchingCluster"
            >
              <div
                style="height: 450px; overflow: scroll;"
              >

                <b-card
                  v-if="standards.length == 0"
                  border-variant="secondary"
                  no-body
                  class="mb-0 p-2 mb-1 cursor-pointer"
                >
                  <p class="mb-0">
                    No Standards Available
                  </p>
                </b-card>
                <b-card
                  v-for="standard, sIndex in standards"
                  :key="sIndex"
                  border-variant="secondary"
                  no-body
                  class="mb-0 p-2 mb-1"
                >
                  <h6>
                    <b-form-checkbox
                      v-if="selectedCluster != '-1'"
                      :id="standard.id"
                      v-model="standard.isChecked"
                      v-b-tooltip="`Move to Noise`"
                      name="check-button"
                      switch
                      inline
                      @change="moveToNoise(standard)"
                    />

                    {{ standard?.standard?.name }}
                    <b-badge
                      pill
                      variant="primary"
                    >{{ standard.standard_id }}
                    </b-badge>
                  </h6>
                  <template v-if="standard?.standard">
                    <p class="mb-0">
                      Grade: {{ standard?.standard.domain.subject.standard_grade.name }}
                    </p>
                    <p class="mb-0">
                      Domain: {{ standard?.standard.domain.name }}
                    </p>
                    <p class="mb-0">
                      Subject: {{ standard?.standard.domain.subject.name }}
                    </p>
                  </template>
                </b-card>
              </div>
            </b-overlay>

          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>

</template>
<script>
import {
  BRow, BCol, BCard, BBadge, BOverlay, BPagination, BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow, BCol, BCard, BBadge, BOverlay, BPagination, BFormCheckbox, BButton,
  },
  data() {
    return {
      clusters: [],
      grades: [],
      standards: [],
      selectedCluster: null,
      isProcessing: false,
      isFetchingCluster: false,
      paginationData: {
        currentPage: 1,
        per_page: 10,
        total: 0,
        lastPage: 0,
      },
    }
  },
  created() {
    this.getClusters()
  },
  methods: {
    getClusters(page = this.paginationData.currentPage, defaultCluster = '-1') {
      this.isProcessing = true
      useJwt.getClusters({
        params: {
          perPage: 20,
          page,
        },

      }).then(response => {
        this.clusters = response.data.data
        this.selectedCluster = defaultCluster || this.clusters[0].cluster
        this.paginationData = {
          currentPage: response.data.pagination.current_page,
          perPage: response.data.pagination.per_page,
          totalItems: response.data.pagination.total,
          lastPage: response.data.pagination.last_page,
        }
        this.getStandard(this.selectedCluster)
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getStandard(cluster) {
      this.selectedCluster = cluster
      this.isFetchingCluster = true
      useJwt.getStandardByCluster(cluster)
        .then(response => {
        //   this.standards = response.data.data
          this.standards = response.data.data.map(standard => ({
            ...standard,
            isChecked: standard.cluster !== -1, // Ensure check state updates
          }))
          console.log(this.standards)
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.isFetchingCluster = false
        })
    },
    moveToNoise(standard) {
      useJwt.moveToNoise({
        id: standard.id,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getClusters(this.paginationData.currentPage, standard.cluster)
      })
    },
    saveClusterToFinal() {
      this.isProcessing = true
      useJwt.saveToFinal().then(response => {
        this.showSuccessMessage(response)
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
<style lang="scss">
.selected {
  background-color: #d9d9d9;
}
</style>
